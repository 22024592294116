var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "tui tuim status-bar color" }, [
    _vm.loading
      ? _c(
          "div",
          { staticClass: "loading" },
          [
            _c("t-placeholder", {
              attrs: { lines: 1, header: true, paragraph: false }
            })
          ],
          1
        )
      : _c(
          "div",
          { staticClass: "colors" },
          _vm._l(_vm.items, function(item, k) {
            return _c("span", { key: k, style: item })
          }),
          0
        ),
    !_vm.loading && _vm.status
      ? _c("span", {
          staticClass: "status",
          domProps: { innerHTML: _vm._s(_vm.status) }
        })
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }