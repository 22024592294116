<template>
  <div class="tui tuim status-bar color">
    <div v-if="loading" class="loading">
      <t-placeholder :lines="1" :header="true" :paragraph="false" />
    </div>
    <div v-else class="colors">
      <span v-for="(item, k) in items" :key="k" :style="item" />
    </div>
    <span class="status" v-if="!loading && status" v-html="status" />
  </div>
</template>

<script>
export default {
  name: 'TStatusColorBar',
  props: {
    loading: {
      type: Boolean,
      default: false
    },
    items: {
      type: Array,
      default: () => []
    },
    status: {
      type: String,
      default: ''
    }
  }
}
</script>
